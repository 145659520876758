// src/pages/AnalysisPage.js
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message, Select, Alert, Tooltip, Spin, Modal, InputNumber } from 'antd';
import { UploadOutlined, ToolOutlined, SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import styled from 'styled-components';
import { useAuthUser } from "../components/AuthUserContext";
import { useNavigate } from 'react-router-dom';
import { StyledHeader, QuestionMarkIcon, ButtonsContainer2 } from "../styles/shared_components";
import DEV_REQUESTS_PREFIX from "../utils/config";
import * as XLSX from 'xlsx';



const { Option } = Select;

const StyledForm = styled(Form)`
  margin: 0 25%;
`;

const FlexContainer = styled.div`
 display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const StyledSelect = styled(Select)`
  flex-grow: 1;
  margin-right: 16px;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AnalysisPageOverlay = styled.div`
  display: ${({ loading }) => (loading ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
`;

const defaultAdvancedSettings = {
  exclusion_too_fast: { 
    value: 300, 
    min: 100, 
    max: 1000, 
    displayName: "Too Fast Trial (ms)", 
    tooltip: "Defines the minimum response time in milliseconds for a trial to be labeled as 'prematurely quick'. Trials faster than this threshold are flagged but not automatically excluded, indicating potentially hasty responses."
  },
  exclusion_max_fast_perc: { 
    value: 10, 
    min: 1, 
    max: 99, 
    displayName: "Fast Trials Participant Exclusion (%)", 
    tooltip: "Specifies the threshold percentage of 'prematurely quick' trials. If a participant exceeds this percentage, it suggests a pattern of rapid responses, leading to their exclusion from the dataset."
  },
  exclusion_max_error_perc: { 
    value: 30, 
    min: 1, 
    max: 99, 
    displayName: "Max Errors Participant Exclusion (%)", 
    tooltip: "Percentage of maximum errors above which a participant is excluded."
  },
  exclusion_max_latency: { 
    value: 30, 
    min: 5, 
    max: 80, 
    displayName: "Max Latency Participant Exclusion (s)", 
    tooltip: "This represents the upper limit of acceptable response time in seconds for any trial. Trials exceeding this duration suggest the participant may have paused or taken a break during the task, leading to their exclusion from the analysis."
  },
  analyze_max_latency: { 
    value: 10, 
    min: 4, 
    max: 20, 
    displayName: "Slow Trials Exclusion (s)", 
    tooltip: "Maximum allowed response time in seconds for a trial to be included in the analysis."
  },
  analyze_min_latency: { 
    value: 400, 
    min: 100, 
    max: 1000, 
    displayName: "Fast Trials Exclusion (ms)", 
    tooltip: "Minimum allowed response time in milliseconds for a trial to be included in the analysis."
  }
};

const AdvancedSettingsModal = ({ settings, updateSettings, submitSettings, restoreSettings, isOpen, onCancel }) => {
  const AdvancedSettingsRow = ({ name, tooltip, value, onChange, min, max }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      <div>
        <strong>{name}</strong>
        <Tooltip title={tooltip}>
          <QuestionMarkIcon />
        </Tooltip>
      </div>
      <InputNumber min={min} max={max} value={value} onChange={onChange} />
    </div>
  );

  return (
    <Modal
      title="Advanced Settings"
      open={isOpen}
      onOk={submitSettings}
      onCancel={onCancel}
      footer={
        <ModalFooterContainer>
          <Button key="cancel" onClick={onCancel}>Cancel</Button>
          <Button key="restore" onClick={restoreSettings}>Restore to default</Button>
          <Button key="submit" type="primary" onClick={submitSettings}>Submit</Button>
        </ModalFooterContainer>
      }
    >
      <div>
        {Object.entries(settings).map(([key, setting]) => (
          <AdvancedSettingsRow
            key={key}
            name={setting.displayName}
            tooltip={setting.tooltip}
            value={setting.value}
            onChange={(value) => updateSettings(key, value)}
            min={setting.min}
            max={setting.max}
          />
        ))}
      </div>
    </Modal>
  );
};

const AnalysisProcessingModal = ({ visible, onOk }) => (
  <Modal
    title="Processing Analysis"
    open={visible}
    onOk={onOk}
    footer={[
      <Button key="ok" type="primary" onClick={onOk}>
        OK
      </Button>,
    ]}
  >
    <p>
      Please wait while we analyze your data. The time it will take can vary based on the amount of participants in your file and the load on our servers. It can take anywhere from half a minute to a few hours. When the analysis is done, you will receive an email with a file containing the analyzed task scores and raw data. This file will also be available through the "My Tasks" page. If you do not receive an email after a few hours, please try again.
    </p>
  </Modal>
);


const AnalysisPage = () => {
  const [fileList, setFileList] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {token} = useAuthUser();
  const [formProcessing, setFormProcessing] = useState(false);
  const [isAdvancedSettingsModalVisible, setIsAdvancedSettingsModalVisible] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(defaultAdvancedSettings);
  const [localAdvancedSettings, setLocalAdvancedSettings] = useState(defaultAdvancedSettings);
  const navigate = useNavigate();
  const [isQualtrics, setIsQualtrics] = useState(true);
  const [isTaskProcessing, setIsTaskProcessing] = useState(false);
  const [isProcessingModalVisible, setIsProcessingModalVisible] = useState(false);



  useEffect(() => {
    setLoading(true);
    axios.get(`${DEV_REQUESTS_PREFIX}/api/get_tasks`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the authorization header
      }
    })
    .then(response => {
      setLoading(false);
      if (response.data && response.data.length > 0) {
        // Assuming your backend returns an array of tasks, we'll need to adjust
        // this if your task objects have different properties than 'value' and 'label'
        const formattedTasks = response.data.map(task => ({
          value: task.id, // assuming 'id' is the unique identifier of a task
          label: task.name, // 'name' is the name of the task
          platform: task.platform, // and 'platform' is the platform of the task
          task_results_url: task.task_results_url // New: the results URL of the task
        }));
        setTaskOptions(formattedTasks);
      } else {
        message.info("No tasks yet, please create one");
      }
    })
    .catch(error => {
      setLoading(false);
      setError('Something went wrong, please try again');
    });
  }, [token]); 

  // Modify the task selection to update the selected task's platform
const handleTaskChange = (value) => {
  // Find the selected task by its value
  const selectedTask = taskOptions.find(task => task.value === value);
  // Update the state with the selected task's platform
  setIsQualtrics(selectedTask.platform === 'qualtrics');
  // Update the state with the processing status
  setIsTaskProcessing(selectedTask.task_results_url === "processing");

  // Also update the form value for the task
  form.setFieldsValue({ task: value });
};

  useEffect(() => {
    if (isAdvancedSettingsModalVisible) {
      setLocalAdvancedSettings(advancedSettings);
    }
  }, [isAdvancedSettingsModalVisible, advancedSettings]);
  

  const beforeUpload = (file) => {
    const fileFormat = file.name.split('.').pop();
    if (!['csv', 'xlsx', 'xls'].includes(fileFormat)) {
      message.error("The file format should be csv or Microsoft Excel formats", 4); // duration is 4 seconds
      return false;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming the first sheet is the one we want to analyze
      const sheet = workbook.SheetNames[0];
      const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      const rowCount = rows.length;

      if (rowCount > 2500) {
        message.error(`The file contains ${rowCount} rows, which exceeds the allowed limit of 2500 rows.`, 4);
        return false
      }

      setFileList([file]);
    };
    reader.readAsArrayBuffer(file);
    return false; // return false to prevent antd from auto-uploading
  };

  // new handleSubmit function - only the axios.post part
  const handleSubmit = () => {

    form.validateFields()
      .then(values => {
        setFormProcessing(true); // Start processing
        console.log('Form values:', values);
        // FormData to handle file upload
        const formData = new FormData();
        formData.append('file', fileList[0]);
        formData.append('task_id', values.task); // 'task' field from the form
        formData.append('qiat_column_name', values.data_column); // 'data_column' field from the form
        formData.append('id_column_name', values.id_column); // 'id_column' field from the form

        // Process advanced settings
        const processedSettings = {
          exclusion_too_fast: advancedSettings.exclusion_too_fast.value,
          exclusion_max_fast_perc: advancedSettings.exclusion_max_fast_perc.value / 100,
          exclusion_max_error_perc: advancedSettings.exclusion_max_error_perc.value / 100,
          exclusion_max_latency: advancedSettings.exclusion_max_latency.value * 1000,
          analyze_max_latency: advancedSettings.analyze_max_latency.value * 1000,
          analyze_min_latency: advancedSettings.analyze_min_latency.value
        };

        // API URL to send the form data to
        let APIURL = '';

        formData.append('advanced_settings', JSON.stringify(processedSettings));

        console.log('Starting processing...');
        if (isQualtrics) {
          APIURL = `${DEV_REQUESTS_PREFIX}/api/process_data_qualtrics`;
        } else {
          APIURL = `${DEV_REQUESTS_PREFIX}/api/process_data_pavlovia`;
        }
        axios.post(APIURL, formData, {
          headers: { 
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          }
        })
          .then(response => {
            // print the response to understand the structure
            console.log('Response from the backend:', response);
            console.log('Response from the backend (data):', response.data);
            console.log('Response from the backend (data.status):', response.data.status);
        
            if (response.data.status === 'processing') { // check if the response is processing
              setFormProcessing(false); // End processing
              setIsProcessingModalVisible(true); // Show the modal
            } else {
              setFormProcessing(false); // End processing
              message.error(`Error: ${response.data.message || "Unable to process the file on the server."}`);
              console.error(`Error details: ${response.data.message || "Server response did not indicate success."}`);
            }
          })
          .catch(error => {
            console.error('Error during server request:', error);
            message.error(`Error during server request: ${error.message}`);
            setFormProcessing(false); // End processing
          });
      });
  };

  const handleProcessingModalOk = () => {
    setIsProcessingModalVisible(false);
    setTimeout(() => {
      navigate('/my-tasks');
    }, 500);
  };
  
  const openAdvancedSettingsModal = () => {
    setLocalAdvancedSettings(advancedSettings);
    setIsAdvancedSettingsModalVisible(true);
  };

  const updateLocalSettings = (key, value) => {
    setLocalAdvancedSettings({
      ...localAdvancedSettings,
      [key]: { ...localAdvancedSettings[key], value },
    });
  };
  
  const submitAdvancedSettings = () => {
    setAdvancedSettings(localAdvancedSettings);
    setIsAdvancedSettingsModalVisible(false);
  };
  

  const restoreDefaultSettings = () => {
    setAdvancedSettings(defaultAdvancedSettings);
    setLocalAdvancedSettings(defaultAdvancedSettings);
  };


  return (
    <Spin spinning={formProcessing} /*indicator={antIcon}*/ size="large" tip="Analysing task's results... This should take around 30 seconds.">
      <AnalysisPageOverlay loading={formProcessing}/>
    <div>
      <StyledHeader>Analyze qIAT Data</StyledHeader>
      <StyledForm form={form} onFinish={handleSubmit}>
      {error && <Alert message={error} type="error" showIcon closable/>}
        <FlexContainer>
          <Form.Item
            name="task"
            rules={[{ required: true, message: "Please choose a task" }]}
            style={{ flexGrow: 2, marginRight: 8 }}
          >
            <StyledSelect
              showSearch
              placeholder="Choose task"
              loading={loading}
              disabled={loading}
              onChange={handleTaskChange}
            >
              {taskOptions.map((task) => (
                <Option key={task.value} value={task.value}>
                  {task.label}
                </Option>
              ))}
            </StyledSelect>
          </Form.Item>

          <Form.Item
            name="upload"
            rules={!isQualtrics ? [] : [{required: true, message: "Please provide the results file" }]}
          >
            <Upload 
              fileList={fileList}
              maxCount={1}
              beforeUpload={beforeUpload}
              onRemove={() => setFileList([])}
            >
              <Button icon={<UploadOutlined />}
              disabled={!isQualtrics}
              >Click to Upload File</Button>
              <Tooltip title={!isQualtrics ? "The task you've chosen doesn't run on Qualtrics, hence the data and its representation is directly accessible through the portal, eliminating the need for you to provide it." : "Ensure your uploaded file is either an Excel or CSV format, containing two key columns: qIAT data and participant identifiers. It's advisable to remove irrelevant participants (like test runs or incomplete entries) beforehand. Blank cells in the data column are acceptable and such entries will be excluded from the qIAT analysis. Our system also supports multiple headers in the file."}>
              <QuestionMarkIcon />
            </Tooltip>
            </Upload>
          </Form.Item>
        </FlexContainer>

        <Form.Item
          name="data_column"
          rules={!isQualtrics ? [] :[{ required: true, message: "Please provide the data column name" }]}
        >
          <Input 
          addonBefore={
            <span style={{width:"40%"}}>
            Column Name For qIAT's Data
            </span>
          }
          placeholder="e.g., qIAT"
          disabled={!isQualtrics}
          suffix={
            <Tooltip title={!isQualtrics? "The task you've chosen doesn't run on Qualtrics, hence the data and its representation is directly accessible through the portal, eliminating the need for you to provide it." : <span>
              Your input must correspond precisely and be case-sensitive to the file's column name.<br /><br />
              Following the Qualtrics integration guide? Then, look for the column named <i>qIAT</i>
            </span>}>
              <QuestionMarkIcon />
            </Tooltip>
          }
        />
        </Form.Item>

        <Form.Item
          name="id_column"
          rules={!isQualtrics ? [] : [{ required: true, message: "Please provide the ID column name" }]}
        >
          <Input 
          addonBefore="Column Name For Response ID"
          placeholder="e.g., ResponseID"
          disabled={!isQualtrics}
          suffix={
            <Tooltip title={!isQualtrics? "The task you've chosen doesn't run on Qualtrics, hence the data and its representation is directly accessible through the portal, eliminating the need for you to provide it." : <span>
              Your input must correspond precisely and be case-sensitive to the file's column name.<br /><br />
              Unless modified post-download from Qualtrics, this column is typically named <i>Response ID</i>.
            </span>}>
              <QuestionMarkIcon />
            </Tooltip>
          }
        />
        </Form.Item>

        <ButtonsContainer2>
          <Button onClick={openAdvancedSettingsModal}>
            <ToolOutlined /> Advanced Settings
          </Button>
          <Tooltip title={isTaskProcessing ? "The task you are trying to analyze is still being processed. Please wait for an email confirming the analysis is done. Once completed, the results file will be available on the 'My Tasks' page." : ""}>
            <Button type="primary" htmlType="submit">
              <SendOutlined /> Submit
            </Button>
          </Tooltip>
        </ButtonsContainer2>
    <br />
    <br />
    <div
    /* center everything iside */
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
    >
      <p>
        Want to analyze the data on your computer using R?{" "}
        <a 
          href="/r-instructions"
          rel="noopener noreferrer">
          Click here
        </a>
        <br />
        <br />
        <br />
        <small style={{ textAlign: "left", display: "block"}}>
        <u>Note:</u> Due to server limitations, the portal can process files with up to 2,500 participants. If your dataset exceeds this limit, please use the provided R code to perform the analysis manually.  
        </small>
      </p>
  </div>
      </StyledForm>
      <AdvancedSettingsModal
        settings={localAdvancedSettings}
        updateSettings={updateLocalSettings}
        submitSettings={submitAdvancedSettings}
        restoreSettings={restoreDefaultSettings}
        isOpen={isAdvancedSettingsModalVisible}
        onCancel={() => setIsAdvancedSettingsModalVisible(false)}
       />
       <AnalysisProcessingModal
          visible={isProcessingModalVisible}
          onOk={handleProcessingModalOk}
        />
    </div>
    
    </Spin>
  );
};

export default AnalysisPage;